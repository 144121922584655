<template lang="pug">
  .car-model-tab
    AppOverlayLoader(:state="carModelsLoading")
    .car-class-details(v-if="isCarFormShown")
      Form(
        ref="selectedCarModel"
        :car-model-id="selectedCarModelId"
        @back="hideCarForm"
      )
    .car-classes(v-else)
      Filters(
        :filters="filters"
        @add-new="showCarForm"
        @change-car-types="handleSelectCarTypes($event)"
        @update-search-value="handleSearch($event)"
      )
      Table(
        :items="carModels"
        :sorting-data="sorting"
        @select-car="selectCarModel"
        @remove-car="removeCarModel"
        @sorting="changeSorting"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // misc
  import { appDebounce } from "@/helpers/common"

  // store modules
  import carModelsSettingsModule from "@/config/store/company_system/car_class_settings/car_models"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  const carModelsSettingsMixin = withStoreModule(carModelsSettingsModule, {
    resetState: true,
    name: "carModelSettingsMixin",
    readers: {
      carModels: "items",
      carModelsLoading: "loading",
      filters: "filters",
      sorting: "sorting",
      pagination: "pagination"
    },
    actions: {
      fetchCarModels: "FETCH_ITEMS",
      deleteCarModel: "DELETE_ITEM"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      Form: () => import("./Form"),
      Filters: () => import("../Filters"),
      Table: () => import("./Table"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [carModelsSettingsMixin, withConfirmation],

    created() {
      this.debouncedFetchModels = appDebounce(() => this.fetchCarModels())
    },

    mounted() {
      this.fetchCarModels()
    },

    data() {
      return {
        isCarFormShown: false,
        selectedCarModelId: null
      }
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.$refs.selectedCarModel?.hasUnsavedChanges })
    },

    methods: {
      hideCarForm() {
        this.isCarFormShown = false
        this.selectedCarModelId = null
        this.fetchCarModels()
      },

      showCarForm() {
        this.isCarFormShown = true
      },

      selectCarModel({ id }) {
        this.selectedCarModelId = id
        this.showCarForm()
      },

      removeCarModel({ id }) {
        this.$confirm({
          title: this.$t("company_system.car_class_settings.delete_car_confirmation"),
          resolve: {
            handler: () => {
              this.deleteCarModel(id)
            }
          }
        })
      },

      handleSelectCarTypes(carTypes) {
        this.changeFilters({ ...this.filters, carTypes: carTypes })
      },

      handleSearch(value) {
        this.changeFilters({ ...this.filters, searchValue: value })
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchModels()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchModels()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchModels()
      }
    }
  }
</script>

<style lang="sass" scoped>
  .car-model-tab
    position: relative
</style>
